.operation-warp {
  width: 100%;
  height: 100vh;
  background: #353944;
  display: flex;
  padding-top: 56px;

  .operation-content {
    width: 100%;
    height: 100vh;
    // padding-left: 324px;
    flex: 1;
    overflow: hidden;
  }
}
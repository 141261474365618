.operation-content-view {
  height: 100%;
  overflow: auto;
}
.operation-content-row {
  display: flex;
  align-items: center;
}
.operation-content-item {
  display: flex;
  align-items: center;
  min-width: 12.5%;
  .item-index {
    border: 1px solid #eee;
    border-radius: 50%;
    height: 20px;
    line-height: 20px;
    width: 20px;
    text-align: center;
    font-size: 12px;
    color: #ccc;
  }
  .item-left {
    // width: 40%;
    display: flex;
    align-items: center;
  }
  .item-num {
    width: 30px;
    text-align: center;
  }
  .item-symbol {
    width: 20px;
    text-align: center;
  }
}
.operation-column-spacing {
}
.operation-content-list {
  display: flex;
  flex-wrap: wrap;
}
.personal-detail-wrap {
  padding: 10px 20px;
  .title {
    height: 30px;
    line-height: 30px;
    font-size: 24px;
  }
  .info {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 14px;
    .info-menu {
      width: 140px;
      height: 100%;
      flex: none;
      font-size: 14px;
    }
  }
}
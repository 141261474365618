.page-view-warp {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  height: 100%;
  overflow: auto;
  margin: auto;
  .page-view-slider {
    width: 300px;
    height: 40px;
    border-radius: 50px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    background: rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
}
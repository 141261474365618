.page-view-scroll {
  // max-height: 100vh;
  // height: 100%;
  overflow: auto;
  // padding: 20px 0;
  .page-view-p {
    width: 600px;
    height: 800px;
    background: #fff;
    border: 1px solid #ccc;
    transform-origin: 0 0;
    margin: auto;
    .page-view-pcontent {
      height: 100%;
      overflow: hidden;
    }
  }
}
.grid-warp {
  border-width: 1px;
  border-style: solid;
  position: relative;
  overflow: hidden;
  flex: none;
  .line {
    opacity: .4;
  }
  .hline,
  .hline1,
  .hline2 {
    width: 100%;
    height: 0;
    border-top: 1px dashed transparent;
    position: absolute;
    top: 50%;
    left: 0;
  }
  .vline,
  .vline1,
  .vline2 {
    width: 0;
    height: 100%;
    border-right: 1px dashed transparent;
    position: absolute;
    top: 0;
    right: 50%;
  }
  .hline1 { top: 33.333%; }
  .hline2 { top: 66.666%; }
  .vline1 { left: 33.333%; }
  .vline2 { left: 66.666%; }
  
  .bline1,
  .bline2 {
    width: 0;
    height: 200%;
    border-right: 1px dashed red;
    position: absolute;
    transform-origin: 50% 50%;
    top: 50%;
    margin-top: -100%;
    right: 50%;
    transform: rotate(45deg);
  }
  .bline2 {
    transform: rotate(135deg);
  }
  .palace,
  .lpalace {
    width: 50%;
    height: 50%;
    border: 1px dashed red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lpalace {
    height: 75%;
  }

  .grid-text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    font-family:
      '楷体_gb2312',
      '楷体',
      'Kaiti SC',
      'STKaiti',
      'AR PL UKai CN',
      'AR PL UKai HK',
      'AR PL UKai TW',
      'AR PL UKai TW MBE',
      'AR PL KaitiM GB',
      'KaiTi',
      'KaiTi_GB2312',
      'DFKai-SB, TW-Kai';
  }
}
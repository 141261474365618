.operation-view-warp {
  display: flex;
  flex-wrap: wrap;

  .operation-view-item {
    display: flex;
    align-items: center;

    &>div {
      flex: none;
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
      }
    }

    .sign-add,
    .sign-minus,
    .sign-multiplication,
    .sign-division,
    .sign-equal {
      width: 20px;
      text-align: center;
      // background: red;
    }
    .sign-solve {
      width: 30px;
      height: 30px;
      border: 1px solid #ccc;
    }
  }
}
.left-panel {
  width: 0;
  // width: 324px;
  height: 100vh;
  background: #fff;
  // padding: 16px;
  // position: fixed;
  // left: 0;
  // top: 0;
  position: relative;
  transition: width .3s ease;
  flex: none;
  z-index: 9;
  // box-sizing: 0 0 20px 20px #333;
  border-right: 1px solid #eee;
  .left-panel-scroll {
    height: 100%;
    overflow: auto;
    display: none;
    padding-right: 14px;
  }

  &.show {
    width: 324px;
    padding: 16px;
    padding-right: 2px;
    .left-panel-scroll {
      display: block;
    }
  }

  .left-panel-expand {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    width: 20px;
    height: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
    z-index: 12;
    border-left: none;
    box-sizing: 0 0 20px 20px #333;
    border: 1px solid #eee;
  }
}
.index-warp {
  width: 100%;
  padding-top: 56px;
  .menu {
    width: 100%;
    .menu-content {
      width: 100%;
      max-width: 1190px;
      min-height: 100px;
      margin: auto;
      padding: 0 20px;
    }
  }
  .page1 {
    background: #353944;
    text-align: center;
    padding: 80px 0;
    .page1-title {
      font-size: 48px;
      text-align: center;
      color: #fff;
    }
  
    .page1-sub-title {
      font-size: 34px;
      line-height: 50px;
      text-align: center;
      color: #9c9c9c;
      margin-top: 30px;
    }
  
    .page1-msg {
      font-size: 22px;
      text-align: center;
      color: #9c9c9c;
      margin-top: 20px;
    }
  
    .page1-btn {
      flex: none;
      width: 140px;
      margin-top: 50px;
    }
  }
  .page2 {
    text-align: center;
    padding: 80px 0;
    .page2-title {
      font-size: 48px;
      text-align: center;
      color: #353944;
    }
    .page2-msg {
      font-size: 34px;
      line-height: 50px;
      text-align: center;
      color: #9c9c9c;
      margin-top: 30px;
    }
    .page2-btn {
      flex: none;
      width: 140px;
      margin-top: 50px;
    }
  }

  @media screen and ( max-width: 500px) {
    .page1 {
      .page1-title { font-size: 30px; }
      .page1-sub-title { font-size: 18px; line-height: 24px; }
      .page1-msg { font-size: 12px; }
    }
    .page2 {
      .page2-title { font-size: 30px; }
      .page2-msg { font-size: 18px; line-height: 24px; }
    }
  }
}
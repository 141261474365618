* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body {
  color: #404040;
  font-size: 16px;
}

.page-outer {
  overflow: hidden;
  margin: auto;
  margin-bottom: 30px;
}
.page-wrap {
  background: #fff;
  overflow: hidden;
  flex: none;
  margin-bottom: 20px;
  position: relative;
  .page-wrap-num {
    position: absolute;
    top: 0;
    left: 0;
    // width: 20px;
    // height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgba(0, 0, 0, .3);
  }
  .page-content {
    height: 100%;
    .page-box {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      position: relative;
      overflow: hidden;
      .page-box-cover {
        width: 100%;
        background: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}